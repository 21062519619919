<template>
    <div>
        <div v-if="!pageIndexApiDataIsLoading">
            <company-licence-component/>

            <div class="row">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5" to="/admin"><i class="fa fa-cogs fa-fw fa-lg"></i></router-link>
                    <router-link class="btn btn-white m-r-5" to="/admin/products"><i class="fa fa-cubes fa-fw fa-lg"></i> Gestion des Articles</router-link>
                    <router-link class="btn btn-white m-r-5 text-orange" to="/admin/clients"><i class="fa fa-users fa-fw fa-lg text-orange"></i> Gestion des Clients</router-link>
                    <router-link class="btn btn-white m-r-5" to="/admin/exports"><i class="fa fa-file-download fa-fw fa-lg"></i> Export des Données</router-link>
                </div>
            </div>

            <!--<h1 class="page-header">Playground  <small class="lead">.</small></h1>-->
            <hr class="bg-grey-lighter" />

            <div class="row m-b-5">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5" to="/admin/clients"><i class="fa fa-users fa-fw fa-lg"></i></router-link>
                    <router-link class="btn btn-white m-r-5" to="/admin/clients/create"><i class="fa fa-plus fa-fw fa-lg"></i> Nouveau</router-link>
                    <router-link class="btn btn-white m-r-5 text-orange" to="/admin/clients/import"><i class="fa fa-download fa-fw fa-lg text-orange"></i> Importer</router-link>
                    <router-link class="btn btn-white m-r-5" to="/admin/clients/export"><i class="fa fa-upload fa-fw fa-lg"></i> Exporter</router-link>
                </div>
            </div>

            <div v-if="pageIndexApiData != null">
                <div class="row">
                    <div class="col-6">
                        <h2>Importer les clients  <small></small></h2>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">Chargez le fichier contenant la liste des clients</h4>
                            </div>
                            <div class="panel-body">
                                <div>
                                    <form class="form-horizontal form-bordered" @submit.prevent="uploadClientFileFormData">
                                        <div class="form-group row">
                                            <div class="col-8">
                                                <input type="file" name="file" @change="clientsFileSelected" ref="clients_file" class="form-control" :disabled="pusherPayload.status == 'loading'">
                                            </div>
                                            <div class="col-4">
                                                <button :disabled="clientsFileUploadFormApiDataIsLoading || clientsFileUploadFormData.file === null || pusherPayload.status == 'loading'"
                                                        type="submit"
                                                        class="btn btn-success btn-block">
                                                    <i class="fas fa-spinner fa-spin" v-if="clientsFileUploadFormApiDataIsLoading"></i>
                                                    <i class="fas fa-upload" v-else></i> Charger
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <h2>Importer les codes clients<small></small></h2>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">Chargez le fichier contenant la liste des codes clients</h4>
                            </div>
                            <div class="panel-body">
                                <div>
                                    <form class="form-horizontal form-bordered" @submit.prevent="uploadClientCodesFileFormData">
                                        <div class="form-group row">
                                            <div class="col-8">
                                                <input type="file" name="file" @change="clientCodesFileSelected" ref="client_codes_file" class="form-control" :disabled="pusherPayload.status == 'loading'">
                                            </div>
                                            <div class="col-4">
                                                <button :disabled="clientsFileUploadFormApiDataIsLoading || clientCodesFileUploadFormData.file === null || pusherPayload.status == 'loading'"
                                                        type="submit"
                                                        class="btn btn-success btn-block">
                                                    <i class="fas fa-spinner fa-spin" v-if="clientsFileUploadFormApiDataIsLoading"></i>
                                                    <i class="fas fa-upload" v-else></i> Importer
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="panel">
                            <div class="panel-body">
                                <div>
                                    <h2 v-if="pusherPayload.status == 'loading'"><i class="fas fa-spinner fa-pulse"></i> Chargement en cours ...</h2>
                                    <h2 v-else>Importation des données.</h2>
                                    <span v-if="clientsFileUploadFormApiDataErrorMessage != null"><small class="lead text-danger">{{clientsFileUploadFormApiDataErrorMessage}}</small><br/></span>
                                    <small class="lead"  :class="{'text-success':pusherPayload.status == 'success','text-warning':pusherPayload.status == 'warning','text-danger':pusherPayload.status == 'error',}" v-if="clientsFileUploadFormApiDataErrorMessage == null && pusherPayload.message != ''">{{pusherPayload.message}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-loader" v-else><span class="spinner-small"></span></div>
    </div>
</template>


<script>
    import pageApi from '../../store/api/admin/clients-import-page-api'
    import LocalStorage from "../../store/local-storage";
    import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'

    export default {
        name: 'clients-import-page-component',
        components: {
            CompanyLicenceComponent,
        },
        props: {},
        watch: {},
        computed: {},
        methods: {
            //Initialization Methods
            loadPageScripts() {
            },

            //Other Methods
            listenToServerBroadcastChannel() {
                let user = LocalStorage.getUserInfo();
                if (user.id != null && user.company_id != null) {
                    window.Echo.channel(window.appConfig.pusherBeamsChannelPrefix + '-clients-upload-private-broadcast-channel-' + user.company_id + '-' + user.id)
                        .listen('ClientsUploadedPrivateEvent', (e) => {
                            this.pusherPayload = e.pusher_payload;
                        });
                    window.Echo.channel(window.appConfig.pusherBeamsChannelPrefix + '-client-codes-upload-private-broadcast-channel-' + user.company_id + '-' + user.id)
                        .listen('ClientCodesUploadedPrivateEvent', (e) => {
                            this.pusherPayload = e.pusher_payload;
                        });
                }
            },

            clientsFileSelected() {
                this.clientsFileUploadFormData.file = this.$refs.clients_file.files[0];
            },

            clientCodesFileSelected() {
                this.clientCodesFileUploadFormData.file = this.$refs.client_codes_file.files[0];
            },


            async getPageIndexApiData() {
                this.pageIndexApiDataIsLoading = true;
                this.pageIndexApiDataErrorMessage = null;
                try {
                    const response = await pageApi.getIndexData();
                    //console.log(response);
                    this.pageIndexApiData = response.data;
                    this.pageIndexApiDataIsLoading = false;
                    this.pageIndexApiDataErrorMessage = null;
                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            LocalStorage.clearSessionData();
                            this.$router.push('login');
                        }  else {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            //
                            this.$swal({
                                icon: 'error',//'info','success','warning','error','question',
                                toast: true,
                                position: 'bottom-end',
                                timer: 3000,
                                showConfirmButton: false,
                                title: 'HTTP ' + error.response.status,
                                text: error.response.data.message,
                            })
                        }
                    } else {
                        this.pageIndexApiDataIsLoading = false;
                        this.pageIndexApiDataErrorMessage = error.message;
                        //
                        this.$swal({
                            icon: 'error',//'info','success','warning','error','question',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                            showConfirmButton: false,
                            //title: error.message
                            text: error.message,
                        })
                    }
                }
            },

            async uploadClientFileFormData() {
                this.clientsFileUploadFormApiDataIsLoading = true;
                this.clientsFileUploadFormApiDataErrorMessage = null;
                this.clientsFileUploadFormApiDataValidationErrors = [];
                try {
                    const response = await pageApi.uploadClientFileFormData(this.clientsFileUploadFormData);
                    //console.log(response);
                    this.clientsFileUploadFormApiData = response.data;
                    this.clientsFileUploadFormApiDataIsLoading = false;
                    this.clientsFileUploadFormApiDataErrorMessage = null;
                    this.clientsFileUploadFormApiDataValidationErrors = [];
                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        this.clientsFileUploadFormApiDataIsLoading = false;
                        this.clientsFileUploadFormApiDataErrorMessage = error.response.data.message;
                        if (error.response.status === 422) {
                            this.clientsFileUploadFormApiDataValidationErrors =  error.response.data.errors;
                        }else {
                            this.clientsFileUploadFormApiDataValidationErrors = [];
                        }
                    } else {
                        this.clientsFileUploadFormApiDataIsLoading = false;
                        this.clientsFileUploadFormApiDataErrorMessage = error.message;
                        this.clientsFileUploadFormApiDataValidationErrors = [];
                    }
                }
            },

            async uploadClientCodesFileFormData() {
                this.clientsFileUploadFormApiDataIsLoading = true;
                this.clientsFileUploadFormApiDataErrorMessage = null;
                this.clientsFileUploadFormApiDataValidationErrors = [];
                try {
                    const response = await pageApi.uploadClientCodesFileFormData(this.clientCodesFileUploadFormData);
                    //console.log(response);
                    this.clientsFileUploadFormApiData = response.data;
                    this.clientsFileUploadFormApiDataIsLoading = false;
                    this.clientsFileUploadFormApiDataErrorMessage = null;
                    this.clientsFileUploadFormApiDataValidationErrors = [];
                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        this.clientsFileUploadFormApiDataIsLoading = false;
                        this.clientsFileUploadFormApiDataErrorMessage = error.response.data.message;
                        if (error.response.status === 422) {
                            this.clientsFileUploadFormApiDataValidationErrors =  error.response.data.errors;
                        }else {
                            this.clientsFileUploadFormApiDataValidationErrors = [];
                        }
                    } else {
                        this.clientsFileUploadFormApiDataIsLoading = false;
                        this.clientsFileUploadFormApiDataErrorMessage = error.message;
                        this.clientsFileUploadFormApiDataValidationErrors = [];
                    }
                }
            },
        },
        data: function () {
            return {
                appName: window.appConfig.appName,
                appCurrency: window.appConfig.appCurrency,
                intervalId01: 0,

                pusherPayload: {
                    status: '',
                    message: ''
                },

                //
                clientsFileUploadFormData: {
                    file: null
                },

                clientCodesFileUploadFormData: {
                    file: null
                },

                //API Data
                //
                pageIndexApiData: null,
                pageIndexApiDataIsLoading: false,
                pageIndexApiDataErrorMessage: null,

                //
                clientsFileUploadFormApiData: null,
                clientsFileUploadFormApiDataIsLoading: false,
                clientsFileUploadFormApiDataErrorMessage: null,
                clientsFileUploadFormApiDataValidationErrors: [],
            };
        },
        created: function () {
            this.getPageIndexApiData();
        },
        mounted: function () {
            this.loadPageScripts();
            this.listenToServerBroadcastChannel();
        },
        beforeDestroy: function () {
        },
    }
</script>
